import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, EventEmitter, Input, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { environment } from '../../../../../environments/environment';
import { EVENT_CONSTANT } from '../../../../constants/event-constants';
import { TRANSLATION_TEMPLATES } from '../../../../constants/translation-templates-constants';
import { GROUP_APPOINTMENTS_FILTER_BEHAVIOUR_TYPE, WIDGET_CONSTANTS } from '../../../../constants/widget-constants';
import { CategoryDbModel } from '../../../../db-models/categories-db.model';
import { EventDao, EventPaginationDao } from '../../../../db-models/event-dao';
import { EventDateDao } from '../../../../db-models/event-date-dao';
import { StoreDbModel } from '../../../../db-models/stores-dao';
import { CustomBookingMessageTemplate } from '../../../../db-models/widget-conf-dao';
import { Partner } from '../../../../models/global';
import { EventState } from '../../../../models/state.model';
import { WidgetColorConf } from '../../../../models/widget-color.model';
import { CategoryService } from '../../../../services/category.service';
import { LoggerService } from '../../../../services/logger.service';
import { StoreService } from '../../../../services/store.service';
import { AlertComponent } from '../../../../shared/components/alert/alert.component';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import { LoaderComponent } from '../../../../shared/components/loader/loader.component';
import { NewAlertComponent } from '../../../../shared/components/new-alert/new-alert.component';
import { NgxClampComponent } from '../../../../shared/components/ngx-clamp/ngx-clamp.component';
import { CalioTranslationSortPipe } from '../../../../shared/pipes/calio-translation-sort.pipe';
import { IsEmptyStringPipe } from '../../../../shared/pipes/is-empty-string.pipe';
import { TranslationPipe } from '../../../../shared/pipes/translation.pipe';
import { TrustHtmlPipe } from '../../../../shared/pipes/trust-html.pipe';

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss'],
  standalone: true,
  imports: [AlertComponent, NewAlertComponent, FormsModule, NgClass, FontAwesomeModule, NgxClampComponent, NgTemplateOutlet, NgStyle, ButtonComponent, LoaderComponent, IsEmptyStringPipe, TranslateModule, TranslationPipe, TrustHtmlPipe, CalioTranslationSortPipe]
})
export class EventCardComponent {

  @Input() noEvents: boolean;
  @Input() globalEventDisabled = false;
  @Input() noActiveEvents = false;
  @Input() events: EventDao[];
  @Input() set rawEvents(rawEvents: EventDao[]) {
    this._rawEvents = rawEvents ? rawEvents.filter(event => event.status === 1 && event.is_secret === 0) : [];
    if (this._partner && this._rawEvents?.length) {
      if (!this.paginationData.isLoading) {
        this.setEventsFilter();
      } else {
        this.filterEventsWithSelectedStore();
        this.filterEventsWithSelectedCategory();
        this.paginationData.isLoading = false;
      }
    }
  };
  @Input() shownMsg = [];
  @Input() eventState: EventState;
  @Input() widgetColorConf: WidgetColorConf;
  @Input() set widgetTemplates(widgetTemplates: CustomBookingMessageTemplate[]) {
    this._widgetTemplates = widgetTemplates;
    if (this._widgetTemplates?.length > 0 && this._partner) {
      this.setupTemplates();
    }
  };
  @Input() screenType = 'LIST';
  @Input() set partner(partner: Partner) {
    this._partner = partner;
    if (this._partner && this._rawEvents?.length) {
      this.setEventsFilter();
    }
  };
  @Input() lang: string;
  @Input() hideGridSwitcher = 'false';
  @Input() showEventsListing = true;
  @Input() filterStores: string[];
  @Input() filterCategories: string[];
  @Input() paginationData: EventPaginationDao;

  @Output() navigateToEvent = new EventEmitter<string>();
  @Output() resetFormEvent = new EventEmitter<string>();
  @Output() autoSelectSlotAndJumpToSummaryEvent = new EventEmitter<void>();
  @Output() loadMoreEvents = new EventEmitter<EventPaginationDao>();

  private translate = inject(TranslateService);
  private categoryService = inject(CategoryService);
  private storeService = inject(StoreService);
  private destroyRef = inject(DestroyRef);

  readonly logoBaseUrl = environment.logoBaseUrl;
  readonly eventConstants = EVENT_CONSTANT;
  readonly eventImgBaseUrl = environment.eventImgBaseUrl;
  readonly deployUrl = environment.deployUrl;
  readonly eventImgFallback = environment.eventImgFallback;
  readonly widgetConstants = WIDGET_CONSTANTS;
  readonly templateContent = TRANSLATION_TEMPLATES;

  widgetBookButtonLabelTemplate: CustomBookingMessageTemplate;
  widgetNoFreeSlotsLabelTemplate: CustomBookingMessageTemplate;
  noEventsLabelTemplate: CustomBookingMessageTemplate;
  hiddenEventsLabelTemplate: CustomBookingMessageTemplate;
  evnetDisabledErrorLabelTemplate: CustomBookingMessageTemplate;
  selectedEventStores: string[] = [];
  selectedEventCategories: string[] = [];
  isGroupAppointmentStoreFeatureEnabled = 0;
  isGroupAppointmentCategoryFeatureEnabled = 0;
  eventStores: StoreDbModel[];
  eventCategories: CategoryDbModel[];
  isEventsCategoriesLoading = false;
  _rawEvents: EventDao[];
  _widgetTemplates: CustomBookingMessageTemplate[] = [];
  _partner: Partner;
  isEventsLoading = true;
  isCategoryLoadingFirstTime = true;

  constructor(
  ) {
    this.translate.onLangChange.subscribe(lang => this.lang = lang.lang);
  }

  setEventsFilter(): void {
    this.isGroupAppointmentStoreFeatureEnabled = Number(this._partner?.is_group_appointment_store_feature_enabled);
    this.isGroupAppointmentStoreFeatureEnabled && this.getMappedEventStores();

    // Filter Store is disabled then call Filter Categories function
    if (!this.isGroupAppointmentStoreFeatureEnabled) {
      this.isGroupAppointmentCategoryFeatureEnabled = Number(this._partner?.is_group_appointment_category_feature_enabled);
      this.isGroupAppointmentCategoryFeatureEnabled && this.getMappedEventCategories(this.selectedEventStores);
    }

    // Filter Store & Filter Categories are disable then show events
    !this.isGroupAppointmentStoreFeatureEnabled && !this.isGroupAppointmentCategoryFeatureEnabled && (this.isEventsLoading = false);
  }

  navigateTo(page: string): void {
    this.navigateToEvent.next(page);
  }

  resetForm(): void {
    this.resetFormEvent.next('next');
  }

  getMappedEventStores(): void {
    this.isEventsLoading = true;
    this.storeService.getMappedEventStores()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: stores => {
          this.eventStores = stores;
          if (this.filterStores?.length) {
            this.selectedEventStores = this.filterStores;
            this.filterEventsWithSelectedStore()
          }

          // Call Filter Categories once Stores are loaded
          this.isGroupAppointmentCategoryFeatureEnabled = Number(this._partner?.is_group_appointment_category_feature_enabled);
          this.isGroupAppointmentCategoryFeatureEnabled && this.getMappedEventCategories(this.selectedEventStores);

          // Filter Categories are disabled then show events
          !this.isGroupAppointmentCategoryFeatureEnabled && (this.isEventsLoading = false);
        },
        error: (error: HttpErrorResponse) => LoggerService.error(error)
      })
  }

  getMappedEventCategories(storeUuid: string[] = null): void {
    this.isEventsCategoriesLoading = true;
    this.isEventsLoading = true;
    this.eventCategories = [];

    // converting uuids to ids
    const store_id = [];
    this.eventStores?.forEach(store => {
      storeUuid?.includes(store?.uuid) && store_id.push(store.id);
    });

    this.categoryService.getMappedEventCategories(store_id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: categories => {
          this.eventCategories = categories;
          if (this.filterCategories?.length && this.isCategoryLoadingFirstTime) {
            this.selectedEventCategories = this.filterCategories;
            this.filterEventsWithSelectedCategory();
            this.isCategoryLoadingFirstTime = false;
          } else {
            this.onCategorySelect();
          }
          this.isEventsCategoriesLoading = false;
          this.isEventsLoading = false;
        },
        error: (error: HttpErrorResponse) => {
          LoggerService.error(error);
          this.isEventsCategoriesLoading = false;
        }
      })
  }

  onStoreSelect(storeUuid?: string): void {
    if (!storeUuid) {
      this.selectedEventStores = [];
    } else {
      const hasAlreadySelected = this.selectedEventStores.indexOf(storeUuid);
      if (hasAlreadySelected !== -1) {
        this.selectedEventStores.splice(hasAlreadySelected, 1);
      } else {
        this.selectedEventStores.push(storeUuid);
      }
    }
    this.filterEventsWithSelectedStore();
    this.isGroupAppointmentCategoryFeatureEnabled && this.getMappedEventCategories(this.selectedEventStores);
  }

  filterEventsWithSelectedStore(): void {
    if (this.selectedEventStores?.length > 0) {
      this.events = this._rawEvents.filter(event => this.selectedEventStores.includes(event?.store?.uuid));
    } else {
      this.events = [...this._rawEvents];
    }
  }

  onCategorySelect(categoryUuid?: string): void {
    this.filterEventsWithSelectedStore();
    if (!categoryUuid) {
      this.selectedEventCategories = [];
    } else {
      const hasAlreadySelected = this.selectedEventCategories.indexOf(categoryUuid);
      if (hasAlreadySelected !== -1) {
        this.selectedEventCategories.splice(hasAlreadySelected, 1);
      } else {
        this.selectedEventCategories.push(categoryUuid);
      }
    }

    if (this.selectedEventCategories?.length) {
      this.filterEventsWithSelectedCategory();
    } else {
      this.events = [...this.events];
    }
  }

  private filterEventsWithSelectedCategory(): void {
    this.events = this.events.filter(event => {
      if (this._partner.group_appointments_filter_behaviour === GROUP_APPOINTMENTS_FILTER_BEHAVIOUR_TYPE.AND) {
        const hasAllCategories = this.selectedEventCategories.every(category => {
          return event.categories.find(eventCategory => eventCategory.uuid === category);
        });
        return hasAllCategories ? event : false;
      } else {
        const filteredCategoryWiseEvents = event?.categories?.filter(category => this.selectedEventCategories.includes(category.uuid));
        return (filteredCategoryWiseEvents?.length > 0);
      }
    });
  }

  selectEvent(event: EventDao): void {
    this.eventState.eventId = event.uuid;
    this.eventState.date = null;
    this.eventState.is_multi_day = event.is_multi_day;
    this.eventState.number_allowed_additional_guests = event.number_allowed_additional_guests;
    this.eventState.additional_guests_enabled = event.additional_guests_enabled;
    this.eventState.meeting_type_id = event.meeting_type_id;
    this.eventState.meeting_type = event.meeting_type;
    this.eventState.place = event.place;
    this.eventState.is_widget_event_guest_email_required = Number(event.is_widget_event_guest_email_required);
    this.eventState.is_widget_event_guest_phone_required = Number(event.is_widget_event_guest_phone_required);
    this.eventState.is_agenda_view_enabled = Number(event.is_agenda_view_enabled);
    if (event.has_one_slot === 1 && event?.slots?.length) {
      let foundedSlot: EventDateDao;
      for (const slot of event.slots) {
        if (slot.start && !moment().isAfter(slot.start)) {
          foundedSlot = Object.assign({}, slot);
          break;
        }
      }

      if (!foundedSlot) {
        this.navigateTo(this.eventConstants.DATE);
      }

      if (foundedSlot.regular_booking_count < foundedSlot.regular_capacity) {
        this.eventState.foundedSlot = foundedSlot;
        this.eventState.has_one_slot = 1;
        this.eventState.slots = event.slots;
        this.autoSelectSlotAndJumpToSummaryEvent.emit();
      } else {
        LoggerService.warn('[Debug] Automatic jump is disabled because regular booking count is greater or equal to regular capacity');
        this.navigateTo(this.eventConstants.DATE);
      }
    } else {
      this.navigateTo(this.eventConstants.DATE);
    }
  }

  toggleView(view: string): void {
    this.screenType = view;
  }

  setupTemplates(): void {
    this.widgetBookButtonLabelTemplate = this._widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.EVENT_WIDGET_LABELS.WIDGET_EVENT_BOOK_LABEL);
    this.widgetBookButtonLabelTemplate && (this.widgetBookButtonLabelTemplate.is_multi_language = 1);

    this.widgetNoFreeSlotsLabelTemplate = this._widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.EVENT_WIDGET_LABELS.WIDGET_EVENT_NO_MORE_FREE_SLOTS_LABEL);
    this.widgetNoFreeSlotsLabelTemplate && (this.widgetNoFreeSlotsLabelTemplate.is_multi_language = 1);

    this.noEventsLabelTemplate = this._widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.EVENT_WIDGET_LABELS.WIDGET_EVENT_NO_EVENTS_LABEL);
    this.noEventsLabelTemplate && (this.noEventsLabelTemplate.is_multi_language = 1);

    this.hiddenEventsLabelTemplate = this._widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.EVENT_WIDGET_LABELS.WIDGET_EVENT_HIDDEN_EVENTS_LABEL)
    this.hiddenEventsLabelTemplate && (this.hiddenEventsLabelTemplate.is_multi_language = 1);

    this.evnetDisabledErrorLabelTemplate = this._widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.EVENT_WIDGET_LABELS.WIDGET_EVENT_DISABLED_ERROR_LABEL)
    this.evnetDisabledErrorLabelTemplate && (this.evnetDisabledErrorLabelTemplate.is_multi_language = 1);
  }

  loadMoreEventsFn(): void {
    if (this.paginationData.totalCounts <= (Number(this.paginationData.limit) * (Number(this.paginationData.page) + 1))) {
      this.paginationData.hideLoadMoreButton = true;
      return;
    }

    this.paginationData.isLoading = true;
    this.paginationData.page += 1;
    this.paginationData.offset = Number(this.paginationData.limit) * Number(this.paginationData.page);
    this.loadMoreEvents.emit(this.paginationData);
  }
}
